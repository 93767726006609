<template lang="html">
  <div class="card card-primary card-outline NoticeList">
    <div class="card-header p-1">
      <div class="card-title">
        <button type="button" class="btn btn-default btn-sm mx-1 mr-2" v-on:click="loadData()"><i class="fas fa-sync"></i></button>
        <!--<div class="btn-group" role="group" aria-label="Basic example">
            <router-link class="btn btn-secondary" :to="{ name: 'NoticeList', query: null}">
                全部
            </router-link>
            <router-link class="btn btn-secondary" v-for="i in types" :to="{ name: 'NoticeList', query: { type: i.key }}">
                {{i.key}}
            </router-link>
        </div>-->
      </div>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm mt-0">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-1">
      <div class="mailbox-controls p-0">
        <ul class="nav nav-tabs nav-nowrap">
          <li class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.readed==false&&query.type==null}" :to="{ query: {...$route.query,page:undefined, readed: false, type: undefined  }}">
              未读
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.readed==null&&query.type==null}" :to="{ query: {...$route.query,page:undefined, readed: undefined, type: undefined }}">
              全部
            </router-link>
          </li>
          <li v-for="i in types" class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.readed==null&&query.type==i.key}" :to="{ query: {...$route.query,page:undefined, readed: undefined, type: i.key }}">
              {{i.key}}
            </router-link>
          </li>
        </ul>
        <!--<div class="form-inline visible-md-inline-block visible-lg-inline-block visible-sm-inline-block">
            <div class="form-group form-group-sm">
                <label>类型</label>
                <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                    <option value="">所有</option>
                    <option v-for="i in types" v-bind:value="i">{{i.key}}</option>
                </select>
            </div>
        </div>-->
        <!--<div class="pull-right">
            <div class="form-inline">
                <div class="form-group form-group-sm">
                </div>
            </div>
        </div>-->
        <!-- /.pull-right -->
      </div>
      <vue-bootstrap4-table :rows="list"
                            :columns="columns"
                            :config="config"
                            :classes="classes"
                            @on-change-query="onChangeQuery">
        <template slot="sort-asc-icon">
          <i class="fas fa-sort-up"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="fas fa-sort-down"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="fas fa-sort"></i>
        </template>
        <template slot="empty-results">
          没有数据
        </template>
        <template slot="active" slot-scope="props">
          <button class="btn btn-default btn-xs mx-1" title="查看" @click="showView(props.row)">
            <i class="fas fa-book-open"></i>
          </button>
          <button class="btn btn-default btn-xs mx-1" title="删除" @click="opendelete(props.row)">
            <i class="fas fa-trash"></i>
          </button>
        </template>
        <template slot="title" slot-scope="props">
          <b v-if="!props.row.readed" class="text-nowrap" @click="showView(props.row)">{{props.row.title}}</b>
          <span v-else class="text-nowrap" @click="showView(props.row)">{{props.row.title}}</span>
        </template>
        <template slot="user" slot-scope="props">
          <div v-if="props.row.user" class="text-nowrap" :title="props.row.user.nickName">{{props.row.user.userName}}</div>
          <div v-else>全体成员</div>
        </template>
        <template slot="readed" slot-scope="props">
          {{props.row.readed ? "是" : "否"}}
        </template>
      </vue-bootstrap4-table>
    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="this.$store.state.loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <!-- end loading -->
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="View">
      <template slot="header">
        <h4 v-if="item" class="modal-title">{{item.title}}</h4>
      </template>
      <form class="form-horizontal">
        <div v-if="item">
          <div v-html="item.text"></div>
        </div>
        <div v-else>
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" @click="$refs.View.hide()">关闭</button>
      </template>
    </Modal>
    <Modal ref="Delete">
      <template slot="header">
        <h4 class="modal-title">删除通知</h4>
      </template>
      <form class="form-horizontal">
        {{item}}
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" @click="$refs.Delete.hide()">取消</button>
        <button type="button" class="btn btn-danger" @click="del(item)" :disabled="updateing">删除</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { Modal } from 'common'
  //import Modal from '@/components/Modal'
  export default {
    components: {
      Modal
    },
    data() {
      return {
        list: [],
        item: {
          guid: null,
          name: null,
          closed: false,
          type: '默认',
        },
        columns: [
          { name: 'title', label: '标题', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'type', label: '类型', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_type" },
          { name: 'addTime', label: '发布时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'readed', label: '已读', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: { ...this.getDefaultQuery(), ...this.$route.query },
        types: [],
        updateing: false
      }
    },
    created() {
      this.loadData()
      this.loadTypes()
      this.query.readed = this.$route.query.readed
    },
    methods: {
      rowClass(item, type) {
        if (!item) return
        if (item.readed === false) return 'text-bold'
      },
      loadTypes() {
        this.$axios.get('/Api/My/Notices/Types').then(res => {
          this.types = res.data
        })
      },
      loadData() {
        var url = "/Api/My/Notices"
        var params = {
          ...this.query
        }
        this.$axios.get(url, { params: params })
          .then((response) => {
            console.log(response)
            this.list = response.data.data
            this.pager.pageIndex = response.data.pageIndex
            this.pager.pageSize = response.data.pageSize
            this.pager.totalPages = response.data.totalPages
            this.pager.totalRecords = response.data.totalRecords

          }).catch((error) => {
            console.log(error)
          })

      },
      showView(item) {
        this.$refs.View.show()
        this.setReaded(item.guid)
        this.loadItem(item.guid)
      },
      setReaded(guid) {
        this.$axios.get('/Api/My/Notices/Readed/' + guid)
          .then(function (response) {
            this.loadData()
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      getDefaultQuery() {
        return {
          page: 1,
          pageSize: 20,
          sort: "addTime",
          order: "desc",
          key: null,
          type: null,
          readed: null,
        }
      },
      getDifferentQuery(query) {
        var dif = {}
        var all = { ...this.query, ...query }
        var def = this.getDefaultQuery()
        for (var i in all) {
          if (def[i] != all[i]) {
            dif[i] = all[i]
          }
        }
        return dif
      },
      setQuery(newquery) {
        if (!newquery) {
          this.query = this.getDefaultQuery()
        }
        var dif = this.getDifferentQuery(newquery)
        console.log(this.$route.query, dif)
        if (!this.$isObjectValueEqual(this.$route.query, dif)) {
          this.$router.push({ query: dif })
        }
      },
      search() {
        this.setQuery({ page: 1 })
      },
      pageChange(page, pageSize) {
        this.setQuery({ page, pageSize })
      },
      onChangeQuery(queryParams) {
        var sort = queryParams.sort[0]
        this.setQuery({ sort: sort.name, order: sort.order })
      },
      loadItem(id) {
        this.item = null;
        this.$axios.get(`/Api/My/Notices/${id}`)
          .then((response) => {
            //console.log(response)
            this.item = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      opendelete(item) {
        console.log(item)
        this.$refs.Delete.show()
        this.loadItem(item.guid)
      },
      del(item) {
        this.updateing = true;
        console.log(item)
        this.$axios.delete(`/Api/My/Notices/${item.guid}`)
          .then((res) => {
            console.log(res)
            this.$refs.Delete.hide()
            this.loadData()
            this.updateing = false;
          })
          .catch((error) => {
            console.log(error)
            this.updateing = false;
          })
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.query = { ...this.getDefaultQuery(), ...to.query }
      this.loadData()
      next()
    }
  }
</script>

<style lang="css">
  .NoticeList .c1 {
    width: 90px;
  }

  .NoticeList .c2 {
    width: 60px;
  }

  .NoticeList .c_dt {
    width: 155px;
  }

  .NoticeList .c_type {
    width: 120px;
  }
</style>
